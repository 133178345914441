<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head :title="(isNew) ? title : item.name" back="Pages.Browse">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised">

		<app-content-box>

			<app-input-content v-model="model.content" theme="information" />

		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			model: {
				id: false,
				name: '',
				content: ''
			}
		}

	},

	validations: {
		model: {
			content: {}
		}
	}

}

</script>

<style scoped>

</style>